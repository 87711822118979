import React from 'react';
import styled from 'styled-components';

const Error = styled.section`
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  > div {
    text-align: center;
    h2 {
      font-family: 'Poppins', sans-serif;
    }
    p {
      font-family: 'Noto Sans KR', sans-serif
    }
  }
`;

export default () => {
  return <Error>
    <div>
      <h2>Page Not Found.</h2>
      <p>페이지를 찾을 수 없습니다.</p>
    </div>
  </Error>
}
